const mailSvg = (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.3335 13.3978C2.06683 13.3978 1.8335 13.2979 1.6335 13.0983C1.4335 12.8986 1.3335 12.6656 1.3335 12.3994V3.74643C1.3335 3.48019 1.4335 3.24722 1.6335 3.04754C1.8335 2.84786 2.06683 2.74802 2.3335 2.74802H13.6668C13.9335 2.74802 14.1668 2.84786 14.3668 3.04754C14.5668 3.24722 14.6668 3.48019 14.6668 3.74643V12.3994C14.6668 12.6656 14.5668 12.8986 14.3668 13.0983C14.1668 13.2979 13.9335 13.3978 13.6668 13.3978H2.3335ZM8.00016 8.37242L2.3335 4.66165V12.3994H13.6668V4.66165L8.00016 8.37242ZM8.00016 7.37401L13.6002 3.74643H2.41683L8.00016 7.37401ZM2.3335 4.66165V3.74643V12.3994V4.66165Z'
      fill='#FF5C00'
    />
  </svg>
);

export default mailSvg;
