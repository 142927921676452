const timeSvg = (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25 0C20.0555 0 15.222 1.46622 11.1108 4.21326C6.99953 6.96029 3.79521 10.8648 1.90302 15.4329C0.0108321 20.0011 -0.484251 25.0277 0.480379 29.8773C1.44501 34.7268 3.82603 39.1814 7.32234 42.6777C10.8187 46.174 15.2732 48.555 20.1228 49.5196C24.9723 50.4843 29.9989 49.9892 34.5671 48.097C39.1353 46.2048 43.0397 43.0005 45.7868 38.8893C48.5338 34.778 50 29.9445 50 25C49.9926 18.3719 47.3562 12.0174 42.6694 7.33056C37.9827 2.64377 31.6281 0.00745333 25 0ZM25 48C20.451 48 16.0042 46.6511 12.2219 44.1238C8.43957 41.5965 5.4916 38.0044 3.75078 33.8017C2.00997 29.599 1.55449 24.9745 2.44195 20.5129C3.32941 16.0514 5.51995 11.9532 8.73656 8.73654C11.9532 5.51994 16.0514 3.3294 20.5129 2.44194C24.9745 1.55448 29.599 2.00996 33.8017 3.75077C38.0044 5.49159 41.5965 8.43955 44.1238 12.2219C46.6511 16.0042 48 20.451 48 25C47.9932 31.0979 45.5679 36.9441 41.256 41.256C36.9441 45.5679 31.0979 47.9932 25 48Z'
      fill='#FF5C00'
    />
    <path
      d='M26 24.586V11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10C24.7348 10 24.4804 10.1054 24.2929 10.2929C24.1054 10.4804 24 10.7348 24 11V25C24 25.1313 24.0258 25.2614 24.0761 25.3827C24.1264 25.504 24.2001 25.6142 24.293 25.707L32.293 33.707C32.4816 33.8892 32.7342 33.99 32.9964 33.9877C33.2586 33.9854 33.5094 33.8803 33.6948 33.6949C33.8802 33.5095 33.9854 33.2586 33.9876 32.9964C33.9899 32.7342 33.8891 32.4816 33.707 32.293L26 24.586Z'
      fill='#FF5C00'
      id='main-svg-arrows'
    />
  </svg>
);

export default timeSvg;
